<template>
  <div>
    <v-skeleton-loader v-if="loading" type="image" />

    <v-sheet light v-if="worksheetObj" v-bind="attrs">
      <v-alert
        class="mx-2"
        dismissible
        v-model="showMsg"
        type="warning"
        v-if="errorMsg"
      >
        {{ errorMsg }}
      </v-alert>

      <v-card :loading="loading" :elevation="2" flat hover class="pb-5 ma-3">
        <div style="background-color: #eeeeee" class="d-flex pt-2">
          <div class="text-caption font-weight-light mr-4">
            <span v-if="index" class="ml-2">{{ index }}</span>
            <v-btn icon @click="viewQrCode(worksheetObj.dynamicQrCodeId)">
              <v-icon>mdi-qrcode</v-icon>
            </v-btn>
            {{ worksheetObj.worksheetType }}
            <span v-if="worksheetObj.processTypeName">
              / {{ worksheetObj.processTypeName }}
            </span>
            <span v-if="worksheetObj && worksheetObj.processDirection">
              /
              {{
                worksheetObj.processDirection == "Input"
                  ? $vuetify.lang.t("$vuetify.worksheet.in")
                  : $vuetify.lang.t("$vuetify.worksheet.out")
              }}
            </span>
            <span v-if="worksheetObj && worksheetObj.supplierName">
              / {{ worksheetObj.supplierName }}
            </span>
            <span v-if="worksheetObj && worksheetObj.customerName">
              / {{ worksheetObj.customerName }}
            </span>
            <span v-if="worksheetObj && worksheetObj.productName">
              / {{ worksheetObj.productName }}
            </span>
          </div>

          <div class="ml-auto mr-2 my-auto font-weight-light">
            <span
              v-if="
                worksheetObj &&
                worksheetObj.processId &&
                worksheetObj.status == 'USED'
              "
            >
              <a
                target="_blank"
                :href="`https://prod.${domain}.com/factory/process/${worksheetObj.processId}`"
              >
                {{ statusLang(worksheetObj.status) }}
              </a>
            </span>
            <span
              v-else-if="
                worksheetObj &&
                worksheetObj.purchaseId &&
                worksheetObj.status == 'USED'
              "
            >
              <a
                target="_blank"
                :href="`https://prod.${domain}.com/purchase/${worksheetObj.purchaseId}`"
              >
                {{ statusLang(worksheetObj.status) }}
              </a>
            </span>
            <span v-else>{{ statusLang(worksheetObj.status) }}</span>
          </div>
        </div>
        <v-card-actions
          class="justify-space-between mx-2"
          v-if="editLocation && locations && locations.length > 0"
        >
          <v-autocomplete
            :label="$vuetify.lang.t('$vuetify.worksheet.location')"
            :placeholder="$vuetify.lang.t('$vuetify.worksheet.chooseLocation')"
            v-model="worksheet.locationId"
            :items="locations"
            item-text="name"
            item-value="id"
            clearable
            solo
            hide-details
            class="mt-2"
            :key="locations ? locations.length : 0"
          />
          <div class="mt-4 mt-md-0">
            <v-btn @click="updateLocation" color="primary" class="ml-4">
              {{ $vuetify.lang.t("$vuetify.base.save") }}
            </v-btn>
            <v-btn @click="editLocation = false" class="ml-4">
              {{ $vuetify.lang.t("$vuetify.base.cancel") }}
            </v-btn>
          </div>
        </v-card-actions>
        <v-card-actions class="justify-space-between">
          <div class="text-caption font-weight-light mt-2">
            <div>
              {{ $vuetify.lang.t("$vuetify.label.id") }}: {{ worksheetObj.id }}
              <span v-if="worksheetObj && worksheetObj.documentNumber">
                / {{ worksheetObj.documentNumber }}
              </span>
            </div>
            <div v-if="worksheetObj && worksheetObj.processTime">
              {{ worksheetObj.processTime | formatDateTimeYear }}
            </div>
          </div>

          <div class="text-center">
            <v-btn
              :color="worksheetObj.locationName ? '' : 'red'"
              v-if="!editLocation"
              @click="
                loadLocations();
                editLocation = true;
              "
              text
            >
              {{
                worksheetObj.locationName
                  ? worksheetObj.locationName
                  : "No Location"
              }}
            </v-btn>
          </div>

          <v-speed-dial
            v-if="showEdit"
            v-model="actions"
            direction="bottom"
            :open-on-hover="false"
            transition="slide-y-transition"
          >
            <template v-slot:activator>
              <v-btn v-model="actions" color="blue darken-2" dark fab small>
                <v-icon v-if="actions"> mdi-close </v-icon>
                <v-icon v-else> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-btn
              v-if="
                editButtons.includes('edit') && worksheetObj.status != 'USED'
              "
              @click="$emit('edit', worksheet)"
              fab
              dark
              small
              color="green"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              v-if="editButtons.includes('print') && canPrint"
              @click="$emit('print', worksheet)"
              fab
              dark
              small
              title="Print Worksheet"
              color="indigo"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
            <v-btn
              v-if="
                editButtons.includes('save-packing-list') &&
                worksheetObj.status == 'SUBMITTED'
              "
              @click="savePackingList"
              fab
              dark
              small
              color="indigo"
              title="Save as Packing List"
            >
              <v-icon>mdi-package-variant-closed-check</v-icon>
            </v-btn>
            <v-btn
              @click="$emit('remove', worksheet)"
              v-if="
                worksheetObj && !submitted && editButtons.includes('delete')
              "
              fab
              dark
              small
              color="red"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-speed-dial>
        </v-card-actions>
        <v-divider class="my-0" />

        <v-card-text>
          <div
            v-if="worksheetObj && worksheetObj.totalNetWeight != 0"
            class="d-flex justify-space-between my-2"
          >
            <div class="font-weight-bold">
              {{ $vuetify.lang.t("$vuetify.base.weight") }}
            </div>
            <div class="mx-5 text-right">
              <v-row style="min-width: 200px" no-gutters>
                <v-col cols="6" v-if="worksheetObj.targetWeight"
                  >{{ $vuetify.lang.t("$vuetify.base.targetWeight") }}:</v-col
                >
                <v-col cols="6" v-if="worksheetObj && worksheetObj.targetWeight"
                  >{{ worksheetObj.targetWeight | formatNumber }}
                  {{ productInfo ? productInfo.measureWeight : "" }}</v-col
                >
                <v-col cols="6" v-if="sum && sum.grossWeight"
                  >{{ $vuetify.lang.t("$vuetify.base.grossWeight") }}:</v-col
                >
                <v-col cols="6" v-if="sum && sum.grossWeight"
                  >{{ sum.grossWeight | formatNumber }}
                  {{ productInfo ? productInfo.measureWeight : "" }}</v-col
                >
                <v-col
                  cols="6"
                  v-if="worksheetObj && worksheetObj.deductibleWeight"
                  >{{
                    $vuetify.lang.t("$vuetify.base.deductibleWeight")
                  }}:</v-col
                >
                <v-col
                  cols="6"
                  v-if="worksheetObj && worksheetObj.deductibleWeight"
                >
                  -{{ sum ? sum.deductibleWeight : 0 | formatNumber }}
                  {{ productInfo ? productInfo.measureWeight : "" }}</v-col
                >
                <v-col cols="6" v-if="totalEntryAdjstmentWeight"
                  >{{
                    $vuetify.lang.t("$vuetify.worksheet.totalEntryAdjustment")
                  }}:</v-col
                >
                <v-col cols="6" v-if="totalEntryAdjstmentWeight"
                  >{{ totalEntryAdjstmentWeight > 0 ? "+" : ""
                  }}{{ totalEntryAdjstmentWeight | formatNumber }}
                  {{ productInfo ? productInfo.measureWeight : "" }}</v-col
                >
                <v-col
                  cols="6"
                  v-if="worksheetObj && worksheetObj.netAdjustmentWeight"
                  >{{
                    $vuetify.lang.t("$vuetify.base.netAdjustmentWeight")
                  }}:</v-col
                >
                <v-col
                  cols="6"
                  v-if="worksheetObj && worksheetObj.netAdjustmentWeight"
                  >{{ worksheetObj.netAdjustmentWeight > 0 ? "+" : ""
                  }}{{ worksheetObj.netAdjustmentWeight | formatNumber }}
                  {{ productInfo ? productInfo.measureWeight : "" }}
                </v-col>
                <v-col
                  cols="6"
                  v-if="worksheetObj && worksheetObj.totalNetWeight"
                  >{{ $vuetify.lang.t("$vuetify.base.netWeight") }}:
                </v-col>
                <v-col
                  cols="6"
                  v-if="worksheetObj && worksheetObj.totalNetWeight"
                  >{{ worksheetObj.totalNetWeight | formatNumber }}
                  {{ productInfo ? productInfo.measureWeight : "" }}
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="d-flex justify-space-between my-2">
            <div class="font-weight-bold my-auto">
              {{ $vuetify.lang.t("$vuetify.base.entries") }}
            </div>
            <div class="text-right">
              <v-chip
                class="mr-5 mb-2"
                @click="entriesDialog = true"
                outlined
                small
              >
                {{
                  worksheetObj && worksheetObj.entries
                    ? worksheetObj.entries.length
                    : 0
                }}
                {{ $vuetify.lang.t("$vuetify.base.entries") }}
              </v-chip>
              <div
                v-if="
                  worksheetObj &&
                  worksheetObj.entries &&
                  worksheetObj.entries.length > 1
                "
                class="mx-5 text-right text-caption"
              >
                <div>
                  {{ sum ? sum.minWeight : 0 | formatNumber }}
                  {{ productInfo ? productInfo.measureWeight : "" }} (min);
                  {{ sum ? sum.maxWeight : 0 | formatNumber }}
                  {{ productInfo ? productInfo.measureWeight : "" }} (max)
                </div>
                <div v-if="sum">
                  {{ sum.minDate | formatDateTime }} ->
                  {{ sum.maxDate | formatDateTime }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-space-between my-2"
            v-if="
              worksheetObj &&
              worksheetObj.files &&
              worksheetObj.files.length > 0
            "
          >
            <div class="font-weight-bold my-auto">
              {{ $vuetify.lang.t("$vuetify.base.attachments") }}
            </div>
            <v-btn class="mr-2" text @click="imgDialog = true">{{
              $vuetify.lang.t(
                "$vuetify.fileInput.counter",
                worksheetObj.files.length
              )
            }}</v-btn>
          </div>
          <div
            v-if="worksheetObj && worksheetObj.vehiclePlateNumber"
            class="d-flex justify-space-between my-2"
          >
            <div class="font-weight-bold">
              {{ $vuetify.lang.t("$vuetify.worksheet.plateNumber") }}
            </div>
            <div class="mx-5">{{ worksheetObj.vehiclePlateNumber }}</div>
          </div>
          <div
            v-if="worksheetObj && worksheetObj.notes"
            class="d-flex justify-space-between my-2"
          >
            <div class="font-weight-bold">
              {{ $vuetify.lang.t("$vuetify.base.notes") }}
            </div>
            <div class="mx-5">{{ worksheetObj.notes }}</div>
          </div>
        </v-card-text>
        <v-card-action>
          <div class="mx-4">
            <div class="text-caption" v-if="worksheetObj && worksheetObj.memo">
              {{ $vuetify.lang.t("$vuetify.base.memo") }}:
              <span class="ml-4">{{ worksheetObj.memo }}</span>
            </div>
            <div class="text-caption font-weight-thin text-right mt-5">
              <div v-if="worksheetObj.submittedTime">
                {{$vuetify.lang.t('$vuetify.base.submittedOn')}} {{ worksheetObj.submittedTime | formatDateTime }}
              </div>
              <div>
                {{$vuetify.lang.t('$vuetify.base.createdOn')}}
                {{ worksheetObj.createTime | formatDateTime }}
              </div>
              <div>
                {{$vuetify.lang.t('$vuetify.base.createdBy')}}
                {{ worksheetObj.staffName }}
              </div>
            </div>
            <div style="position: absolute; margin-top: -25px">
              <v-icon :title="confirmedBy" v-if="confirmedBy" color="green">
                mdi-check-decagram
              </v-icon>
            </div>
          </div>
        </v-card-action>
      </v-card>

      <v-dialog
        style="z-index: 1000"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        v-model="entriesDialog"
      >
        <v-card v-if="worksheetObj">
          <v-card-title class="d-flex justify-space-between">
            <div class="text-subtitle-1">
              {{ $vuetify.lang.t("$vuetify.worksheet.worksheet") }}
              {{ worksheetObj.id }}
              <div
                class="text-caption font-weight-light"
                v-if="worksheetObj.documentNumber"
              >
                {{ $vuetify.lang.t("$vuetify.base.documentNumber") }}:
                {{ worksheetObj.documentNumber }}
              </div>
            </div>
            <v-btn icon @click="entriesDialog = false" >
              <v-icon>mdi-close</v-icon> 
            </v-btn >
          </v-card-title>
          <v-divider />
          <v-card-text>
            <worksheet-items
              :worksheet.sync="worksheetObj"
              :sum.sync="sum"
              :worksheetTypeFields="worksheetTypeFields"
              :productInfo="productInfo"
              :editable="false"
            />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        style="z-index: 1000"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        v-model="imgDialog"
      >
        <v-card v-if="worksheetObj">
          <v-card-title class="d-flex justify-space-between">
            <div class="text-subtitle-1">
              {{ $vuetify.lang.t("$vuetify.worksheet.worksheet") }}
              {{ worksheetObj.id }}
              <div
                class="text-caption font-weight-light"
                v-if="worksheetObj.documentNumber"
              >
                {{ $vuetify.lang.t("$vuetify.base.documentNumber") }}:
                {{ worksheetObj.documentNumber }}
              </div>
              <div class="text-caption font-weight-light" 
                v-if="worksheetObj && worksheetObj.deductibleWeight" >
                {{
                    $vuetify.lang.t("$vuetify.base.deductibleWeight")
                }}:
                {{
                    worksheetObj.deductibleWeight | formatNumber
                }}
                {{ productInfo ? productInfo.measureWeight : "" }}
              </div>
            </div>
            <v-btn @click="imgDialog = null" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text>
            <v-carousel continuous hide-delimiter-background height="80vh" v-if="images && images.length > 0">
              <v-carousel-item
                v-for="(img, i) in images"
                :key="i"
                reverse-transition="fade-transition"
                transition="fade-transition"
              >
                <v-container class="fill-height align-items-center justify-content-center">
                  <v-layout class="flex-column" justify-center>
                    <div class="text-caption mx-auto">
                      {{ img.time | formatDateTime }}
                    </div>
                    <v-img contain aspect-ratio="1.7" :src="img.src" />
                  </v-layout>
                </v-container>
              </v-carousel-item>
            </v-carousel>
            <div v-else class="text-subtitle-2 font-weight-light">
              No attachments found
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { parseDomain } from "parse-domain";
const WorksheetItems = () =>
  import("@/components/processWorksheet/WorksheetItems.vue");
import { getProcessWorksheet } from "@/services/processWorksheet";
import { sumWorksheetWeight } from "@/services/worksheetServices";
import { saveWorksheetAsPackingList } from "@/services/packingListServices";
import { getDynamicQrCodeById } from "@/services/dynamicQrCode";

export default {
  data: () => {
    return {
      loading: true,
      confirmedBy: false,
      entriesDialog: false,
      imgDialog: false,
      productInfo: null,
      sum: null,
      actions: false,
      worksheetObj: null,
      showEdit: false,
      editLocation: false,
      errorMsg: null,
      showMsg: false,
      loading: false,
    };
  },
  components: {
    WorksheetItems,
  },
  props: {
    worksheet: {
      type: [Object, Number],
      required: true,
    },
    showEditButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    editButtons: {
      type: Array,
      required: false,
      default: () => ["print", "edit", "delete"],
    },
    salesOrderId: {
      type: Number,
      required: false,
    },
    index: {
      type: Number,
      required: false,
    },
  },
  async created() {
    this.loading = true;

    if (this.worksheet && typeof this.worksheet == "object") {
      this.worksheetObj = this.worksheet;
    } else if (
      this.worksheet &&
      ((typeof this.worksheet == "number" && this.worksheet > 0) ||
        typeof this.worksheet == "string")
    ) {
      const worksheetId = this.worksheet;
      await getProcessWorksheet(worksheetId).then((response) => {
        this.worksheetObj = response.data;
      });
    } else {
      console.error("No worksheet found using: " + this.worksheet);
    }

    const parseResult = parseDomain(window.location.hostname);
    const { domain } = parseResult;
    this.domain = domain;

    await this.fetchWorksheetTypeDefinitions();
    await this.loadProductInfo();

    this.showEdit = this.showEditButtons;

    this.sumWeight();

    this.checkForConfirmation();

    this.loading = false;

  },
  computed: {
    ...mapGetters("worksheetTypeDefinition", ["worksheetTypeDefinitions"]),
    ...mapGetters("locations", ["locations"]),

    hasEntries() {
      return (
        this.worksheetObj &&
        this.worksheetObj.entries &&
        this.worksheetObj.entries.length > 0
      );
    },
    worksheetTypeFields() {
      if (
        this.worksheetObj &&
        this.worksheetTypeDefinitions &&
        this.worksheetTypeDefinitions.length > 0 &&
        this.worksheetObj.worksheetTypeId
      ) {
        return this.worksheetTypeDefinitions.find(
          (t) => t.id == this.worksheetObj.worksheetTypeId
        );
      } else {
        return {};
      }
    },
    images() {
      if (
        this.worksheetObj &&
        this.worksheetObj.files &&
        this.worksheetObj.files.length > 0
      ) {
        return this.worksheetObj.files.map((file) => {
          const src = `${process.env.VUE_APP_BASE_URL}/file/worksheet/${file.id}`;
          return { src, id: file.id, time: file.createdTime };
        });
      } else {
        return [];
      }
    },
    submitted() {
      if (
        this.worksheetObj &&
        ["SUBMITTED", "USED"].includes(this.worksheetObj.status)
      ) {
        return true;
      } else {
        return false;
      }
    },
    totalEntryAdjstmentWeight() {
      if (this.hasEntries) {
        const totalAdjstmentWeight = this.worksheetObj.entries.reduce(
          (sum, e) => (sum += e.netAdjustmentWeight),
          0
        );
        return totalAdjstmentWeight;
      } else {
        return 0;
      }
    },
    canPrint() {
      if (
        this.worksheet &&
        (this.worksheet.processDirection == "Output" ||
          this.worksheet.customerId)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions("worksheetTypeDefinition", ["fetchWorksheetTypeDefinitions"]),
    ...mapActions("categoryStore", ["fetchCategory"]),
    ...mapActions("locations", ["loadLocations"]),
    ...mapActions("processWorksheet", ["updateProcessWorksheet"]),
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),

    async loadProductInfo() {
      if (this.worksheetObj && this.worksheetObj.productId) {
        return await this.fetchCategory(this.worksheetObj.productId).then(
          (product) => {
            this.productInfo = product;
            return this.productInfo;
          }
        );
      } else {
        return null;
      }
    },
    async sumWeight() {
      this.sum = await sumWorksheetWeight(this.worksheetObj);
      if (this.worksheetObj) {
        this.worksheetObj.totalNetWeight = this.sum.netWeight;
      }
    },
    statusLang(status) {
      let translation = status;
      switch (status) {
        case "SUBMITTED":
          translation = this.$vuetify.lang.t("$vuetify.worksheet.submitted");
          break;
        case "PENDING":
          translation = this.$vuetify.lang.t("$vuetify.worksheet.pending");
          break;
        case "USED":
          translation = this.$vuetify.lang.t("$vuetify.worksheet.used");
          break;
      }

      return translation;
    },
    savePackingList() {
      if (!this.salesOrderId) {
        this.errorMsg = "Sales order is not defined";
        this.showMsg = true;
        return;
      }

      this.loading = true;

      const postData = {
        salesOrderId: this.salesOrderId,
        worksheetId: this.worksheetObj.id,
      };
      saveWorksheetAsPackingList(postData)
        .then(() => {
          this.$emit("used", this.worksheetObj);
          this.loading = false;
        })
        .catch((error) => {
          this.errorMsg = error.data.message;
          this.showMsg = true;
          this.loading = false;
        });

      console.log("Saving working as packing list");
    },
    updateLocation() {
      this.updateProcessWorksheet(this.worksheetObj)
        .then((response) => {
          this.editLocation = false;
          const newLocation = this.locations.find(
            (l) => l.id == this.worksheetObj.locationId
          );
          this.worksheetObj.locationName = newLocation
            ? newLocation.name
            : null;
          this.addSuccessMessage("Worksheet location updated successfully");
        })
        .catch(() => {
          this.addErrorMessage(
            "Error updating Worksheet location, please try again later"
          );
        });
    },
    viewQrCode(qrCodeId) {
      getDynamicQrCodeById(qrCodeId).then((response) => {
        const dynamicQrCode = response.data;
        this.$router.push({
          name: "worksheetQRCodePrint",
          params: { uuid: dynamicQrCode.uuid },
        });
      });
    },
    checkForConfirmation() {
      if (this.worksheetObj && this.worksheetObj.id) {
        const params = {
          worksheetIds: this.worksheetObj.id,
        };
        this.$axios("/worksheetSettlement/by-worksheets", { params }).then(
          (resp) => {
            const confirmations = resp.data;
            if (confirmations && confirmations.length > 0) {
              this.confirmedBy = confirmations
                .map((c) => {
                  const name = c.supervisorName;
                  const time = this.$options.filters.formatDateTimeYear(
                    c.createdDate
                  );
                  return `${name} on ${time}`;
                })
                .join(", ");
            }
          }
        );
      }
    },
  },
};
</script>

<style>
.nowrap{
  white-space: nowrap;
}
</style>
